import React, { useContext, useState } from "react";
import Slide1 from "../assets/carousel/slide1.png";
import Slide2 from "../assets/carousel/slide5.png";
import Slide3 from "../assets/carousel/slide3.png";

import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Washprogramcontrols from "./homecomponents/Washprogramcontrols";
import Washprogram from "./homecomponents/Washprogram";
import UserReview from "./homecomponents/UserReview";
import SocialMedia from "./homecomponents/SocialMedia";
import Spinner from "./Spinner";
import { useRef } from "react";
import PickupAddress from "./homecomponents/PickupAddress";
import CryptoJS from "crypto-js";
import NewAddressSection from "./homecomponents/NewAddressSection";

export default function Home() {
  const context = useContext(AppContext);
  const {
    setUserData,
    userdata,
    allReviews,
    hreviewloading,
    hreviewError,
    selectedProgram,
    setSelectedProgram,
    fetchAddress,
    pickupAdd,
    variations,
    allwashPrograms,
    detergent,
    setDetergent,
    conditioner,
    setConditioner,
    ironType,
    setIronType,
    programRate,
    setProgramRate,
    sendDataToApp,
    orderStage,
    setOrderStage,
    addOrderRequest,
  } = context;
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [locationStatus, setLocationStatus] = useState(null);
  const [area, setArea] = useState("none");

  let total_reviews = 0;
  const carouselNext = useRef();

  window.addEventListener("message", (message) => {
    let data = JSON.parse(message.data);
    setUserData(data.user);
    // console.log("data received");
    // console.log(data);
    // setLocation(data.location);
    // setLocationStatus(data.locationStatus);

    // if (typeof message.data == "string" && message.data != "") {
    // }
  });
  document.addEventListener("message", (message) => {
    let data = JSON.parse(message.data);
    setUserData(data.user);
    // console.log("data received");
    // console.log(data);
    // setLocation(data.location);
    // setLocationStatus(data.locationStatus);
    // if (typeof message.data == "string" && message.data != "") {
    // }
  });
  window.scrollTo(0, 0);
  useEffect(() => {
    setTimeout(() => {
      carouselNext.current.click();
    }, 4000);
  }, []);
  // useEffect(() => {
  //   console.log("location is ");
  //   console.log(location);
  //   console.log("locationstatus is");
  //   console.log(locationStatus);
  // }, [location, locationStatus]);
  return (
    <div>
      <div className="">
      <div style={{
        position:'fixed',
        height:'100vh',
        width:'100%',
        backgroundColor:'rgba(0,0,0,0.7)',
        top:0,
        zIndex:9999
      }} className="simpleflex">
        <div style={{
          backgroundColor:'white',
          borderRadius:7,
          flexDirection:'column',
          padding:20
        }} class="simpleflex">
        <h3>App Update Required</h3>
        <span>Visit your application store to update our application to enjoy new ui design and bug free experience!</span>
        </div>
      </div>
        <div
          className="cart-container"
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 9999,
            height: "100%",
            display: selectedProgram.program_name ? "block" : "none",
          }}
        >
          <div
            className="cart-backdrop"
            onClick={() => {
              setSelectedProgram({ program_name: null });
              if (area != "none") {
                setOrderStage(1);
              } else {
                setOrderStage(0);
              }
            }}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          ></div>
          <div
            className="cart-wrapper border "
            style={{
              position: "absolute",
              zIndex: 9999,
              bottom: 0,
              width: "100%",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              paddingBottom: "0.5rem",
              paddingTop: "1rem",
              backgroundColor: "white",
              height: "100%",
              display: "flex",
              height: "80%",
              overflow: "scroll",
              flexDirection: "column",
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              transition: "height 2s",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                paddingRight: 10,
              }}
            >
              <span
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: "50%",
                  backgroundColor: "white",
                }}
                className="simpleflex cart-close-btn"
                onClick={() => {
                  setSelectedProgram({ program_name: null });
                  if (area != "none") {
                    setOrderStage(1);
                  } else {
                    setOrderStage(0);
                  }
                }}
              >
                x
              </span>
            </div>
            <div
              className="cart-body-wrapper p-2"
              style={{ display: "flex", flex: 7, flexDirection: "column" }}
            >
              {orderStage == 0 && (
                <div>
                  <div
                    className="simpleflex"
                    style={{ flexDirection: "column" }}
                  >
                    <label htmlFor="selectarea" className="mb-2">
                      Select your area
                    </label>
                    <select
                      name="area"
                      id="selectarea"
                      className="form-select form-select-sm"
                      onChange={(e) => {
                        setArea(e.target.value);
                        if (e.target.value == "vatika") {
                          if (selectedProgram.program_name == "Wash") {
                            for (let z = 0; z < allwashPrograms.length; z++) {
                              if (allwashPrograms[z].program_name == "Wash") {
                                let pRate = allwashPrograms[z].program_rate;
                                setSelectedProgram(allwashPrograms[z]);
                                setDetergent("surfexcel");
                                setConditioner("softtouch");
                                setIronType(null);
                                for (let i = 0; i < variations.length; i++) {
                                  if (
                                    variations[i].v_name == "surfexcel" ||
                                    variations[i].v_name == "softtouch"
                                  ) {
                                    pRate =
                                      parseInt(pRate) +
                                      parseInt(variations[i].v_rate);
                                  }
                                }
                                pRate = pRate + 10;
                                setProgramRate(pRate);
                              }
                            }
                          }
                          if (selectedProgram.program_name == "Wash + Iron") {
                            for (let z = 0; z < allwashPrograms.length; z++) {
                              if (
                                allwashPrograms[z].program_name == "Wash + Iron"
                              ) {
                                let pRate = allwashPrograms[z].program_rate;
                                console.log(pRate);
                                setDetergent("surfexcel");
                                setConditioner("softtouch");
                                setIronType("steam");
                                for (let i = 0; i < variations.length; i++) {
                                  if (
                                    variations[i].v_name == "surfexcel" ||
                                    variations[i].v_name == "softtouch" ||
                                    variations[i].v_name == "steam"
                                  ) {
                                    pRate =
                                      parseInt(pRate) +
                                      parseInt(variations[i].v_rate);
                                  }
                                }
                                pRate = pRate + 10;
                                setProgramRate(pRate);
                              }
                            }
                          }
                          if (selectedProgram.program_name == "Iron") {
                            for (let z = 0; z < allwashPrograms.length; z++) {
                              if (allwashPrograms[z].program_name == "Iron") {
                                let pRate = allwashPrograms[z].program_rate;
                                setSelectedProgram(allwashPrograms[z]);
                                setDetergent(null);
                                setConditioner(null);
                                setIronType("steam");
                                for (let i = 0; i < variations.length; i++) {
                                  if (variations[i].v_name == "steam") {
                                    pRate =
                                      parseInt(pRate) +
                                      parseInt(variations[i].v_rate);
                                  }
                                }
                                pRate = pRate + 5;
                                setProgramRate(pRate);
                              }
                            }
                          }
                        } else {
                          if (selectedProgram.program_name == "Wash") {
                            for (let z = 0; z < allwashPrograms.length; z++) {
                              if (allwashPrograms[z].program_name == "Wash") {
                                let pRate = allwashPrograms[z].program_rate;
                                setSelectedProgram(allwashPrograms[z]);
                                setDetergent("surfexcel");
                                setConditioner("softtouch");
                                setIronType(null);
                                for (let i = 0; i < variations.length; i++) {
                                  if (
                                    variations[i].v_name == "surfexcel" ||
                                    variations[i].v_name == "softtouch"
                                  ) {
                                    pRate =
                                      parseInt(pRate) +
                                      parseInt(variations[i].v_rate);
                                  }
                                }
                                setProgramRate(pRate);
                              }
                            }
                          }
                          if (selectedProgram.program_name == "Wash + Iron") {
                            for (let z = 0; z < allwashPrograms.length; z++) {
                              if (
                                allwashPrograms[z].program_name == "Wash + Iron"
                              ) {
                                let pRate = allwashPrograms[z].program_rate;
                                setDetergent("surfexcel");
                                setConditioner("softtouch");
                                setIronType("steam");
                                for (let i = 0; i < variations.length; i++) {
                                  if (
                                    variations[i].v_name == "surfexcel" ||
                                    variations[i].v_name == "softtouch" ||
                                    variations[i].v_name == "steam"
                                  ) {
                                    pRate =
                                      parseInt(pRate) +
                                      parseInt(variations[i].v_rate);
                                  }
                                }
                                setProgramRate(pRate);
                              }
                            }
                          }
                          if (selectedProgram.program_name == "Iron") {
                            for (let z = 0; z < allwashPrograms.length; z++) {
                              if (allwashPrograms[z].program_name == "Iron") {
                                let pRate = allwashPrograms[z].program_rate;
                                setSelectedProgram(allwashPrograms[z]);
                                setDetergent(null);
                                setConditioner(null);
                                setIronType("steam");
                                for (let i = 0; i < variations.length; i++) {
                                  if (variations[i].v_name == "steam") {
                                    pRate =
                                      parseInt(pRate) +
                                      parseInt(variations[i].v_rate);
                                  }
                                }
                                setProgramRate(pRate);
                              }
                            }
                          }
                        }
                      }}
                      value={area}
                    >
                      <option value="none">None</option>
                      <option value="manesar">Manesar, Gurugram</option>
                      <option value="vatika">
                        Vatika India Next, Gurugram
                      </option>
                    </select>
                  </div>
                  <div className="simpleflex">
                    <button
                      disabled={area == "none" ? true : false}
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        if (orderStage == 0 && area != "none") {
                          setOrderStage(1);
                        }
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              )}
              {orderStage != 0 && (
                <div className="d-flex justify-content-between mb-3 border-bottom pb-3">
                  <span style={{ fontSize: 15 }} className="text-secondary">
                    Region -{" "}
                    <span style={{ color: "black" }}>
                      {area == "manesar" ? "Manesar" : ""}
                      {area == "vatika" ? "Vatika India Next" : ""}{" "}
                    </span>
                  </span>
                  <span
                    className="text-primary"
                    style={{ fontSize: 15 }}
                    onClick={() => {
                      setOrderStage(0);
                    }}
                  >
                    change
                  </span>
                </div>
              )}
              {orderStage == 1 && (
                <div>
                  <div className="wash-option-wrapper">
                    {detergent && (
                      <div className="wash-detergent-wrapper my-1">
                        <div className="wash-detergent-heading-wrapper simpleflex my-2">
                          <span className="text-muted">DETERGENT</span>
                        </div>
                        <div
                          className="wash-detergent-options-wrapper"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div
                            className={`border p-2 mx-2 simpleflex ${
                              detergent == "surfexcel" ? " border-primary" : ""
                            }`}
                            style={{ flex: 1, backgroundColor: "white" }}
                            onClick={() => {
                              if (detergent != "surfexcel") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (detergent == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "surfexcel") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setDetergent("surfexcel");
                              }
                            }}
                          >
                            <span style={{ fontSize: 15 }}>Surf Excel</span>
                          </div>
                          <div
                            className={`border p-2 mx-2 simpleflex ${
                              detergent == "ariel" ? " border-primary" : ""
                            }`}
                            style={{ flex: 1, backgroundColor: "white" }}
                            onClick={() => {
                              if (detergent != "ariel") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (detergent == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "ariel") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setDetergent("ariel");
                              }
                            }}
                          >
                            <span style={{ fontSize: 15 }}>Ariel</span>
                          </div>
                          <div
                            className={`border p-2 mx-2 simpleflex ${
                              detergent == "tide" ? " border-primary" : ""
                            }`}
                            style={{ flex: 1, backgroundColor: "white" }}
                            onClick={() => {
                              if (detergent != "tide") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (detergent == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "tide") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setDetergent("tide");
                              }
                            }}
                          >
                            <span style={{ fontSize: 15 }}>Tide</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {conditioner && (
                      <div className="wash-conditioner-wrapper my-3">
                        <div className="wash-conditioner-heading-wrapper simpleflex my-2">
                          <span className="text-muted">FABRIC CONDITIONER</span>
                        </div>
                        <div
                          className="wash-conditioner-options-wrapper my-2"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div
                            className={`border p-2 mx-2 simpleflex ${
                              conditioner == "comfort" ? "  border-primary" : ""
                            }`}
                            onClick={() => {
                              if (conditioner != "comfort") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (conditioner == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "comfort") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setConditioner("comfort");
                              }
                            }}
                            style={{ flex: 1, backgroundColor: "white" }}
                          >
                            <span style={{ fontSize: 15 }}>Comfort</span>
                          </div>
                          <div
                            className={`border p-2 mx-2 simpleflex ${
                              conditioner == "softtouch"
                                ? " border-primary"
                                : ""
                            }`}
                            onClick={() => {
                              if (conditioner != "softtouch") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (conditioner == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "softtouch") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setConditioner("softtouch");
                              }
                            }}
                            style={{ flex: 1, backgroundColor: "white" }}
                          >
                            <span style={{ fontSize: 15 }}>Softtouch</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {ironType && (
                      <div className="wash-irontype-wrapper my-3">
                        <div className="wash-irontype-heading-wrapper simpleflex my-2">
                          <span className="text-muted">IRON TYPE</span>
                        </div>
                        <div
                          className="wash-irontype-options-wrapper my-2"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          {/*<div
                            className={`border p-2 mx-2 simpleflex ${
                              ironType == "dry" ? "border-primary" : ""
                            }`}
                            onClick={() => {
                              if (ironType != "dry") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (ironType == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "dry") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setIronType("dry");
                              }
                            }}
                            style={{ flex: 1 }}
                          >
                            <span style={{fontSize:15}}>Dry</span>
                          </div>*/}
                          <div
                            className={`border p-2 mx-2 simpleflex ${
                              ironType == "steam" ? " border-primary" : ""
                            }`}
                            onClick={() => {
                              if (ironType != "steam") {
                                let pRate = programRate;
                                let newRate = 0;
                                let oldRate = 0;
                                for (let j = 0; j < variations.length; j++) {
                                  if (ironType == variations[j].v_name) {
                                    oldRate = parseInt(variations[j].v_rate);
                                  }
                                  if (variations[j].v_name == "steam") {
                                    newRate = parseInt(variations[j].v_rate);
                                  }
                                }
                                pRate = pRate - oldRate + newRate;
                                setProgramRate(pRate);
                                setIronType("steam");
                              }
                            }}
                            style={{ flex: 1, backgroundColor: "white" }}
                          >
                            <span style={{ fontSize: 15 }}>Steam</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {orderStage == 2 && (
                <div style={{ maxHeight: 310, overflow: "scroll" }}>
                  <PickupAddress />
                  {!userdata.loggedin && (
                    <div
                      style={{ height: "200", flexDirection: "column" }}
                      className="simpleflex my-2"
                    >
                      <span className="my-2">
                        Before adding address you need to login first
                      </span>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          let datatosend = { login: "navigate to login" };
                          sendDataToApp(datatosend);
                        }}
                      >
                        Login
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            {orderStage != 0 && (
              <div
                className="cart-footer-wrapper my-2 border-top pt-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  backgroundColor: "white",
                }}
              >
                <div
                  className="cp-info simpleflex"
                  style={{ height: "100%", flex: 3, flexDirection: "column" }}
                >
                  <div className="simpleflex">
                    <strong>
                      <span className="text-muted small">PROGRAM</span>
                    </strong>
                  </div>
                  <div className="simpleflex">
                    <span
                      className=""
                      style={{ fontSize: 17, fontWeight: 500 }}
                    >
                      {selectedProgram.program_name
                        ? selectedProgram.program_name
                        : "undefined"}
                    </span>
                  </div>
                </div>
                <div
                  className="cp-rate simpleflex"
                  style={{ height: "100%", flex: 3, flexDirection: "column" }}
                >
                  <div className="simpleflex">
                    <strong>
                      <span className="text-muted small">RATE</span>
                    </strong>
                  </div>
                  <div className="simpleflex">
                    <span
                      className=""
                      style={{ fontSize: 17, fontWeight: 500 }}
                    >
                      &#8377;
                      {selectedProgram.program_name
                        ? `${programRate}/${selectedProgram.unit}`
                        : ""}
                    </span>
                  </div>
                </div>
                <div
                  className="cp-btn-wrapper simpleflex"
                  style={{ height: "100%", flex: 4 }}
                >
                  <button
                    className={`btn ${
                      orderStage == 2
                        ? pickupAdd
                          ? "btn-primary"
                          : "btn-secondary"
                        : "btn-primary"
                    }`}
                    onClick={() => {
                      if (orderStage == 0) {
                        setOrderStage(1);
                      }
                      if (orderStage == 1) {
                        setOrderStage(2);
                      }
                      if (orderStage == 2) {
                        if (pickupAdd) {
                          console.log("order placed successfully");
                          navigate("/confirm");
                        }
                      }
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div></div>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={require("../assets/carousel/slide1.png")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src={require("../assets/carousel/slide2.png")}
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            ref={carouselNext}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="container">
          <div className="wash-program-wrapper">
            <div className="washprogram-header text-center">
              <span className="washprogram-heading">Select Service</span>
            </div>
            <div className="washprogram-container" style={{ padding: 10 }}>
              <div
                className="wp-wrapper"
                style={{ height: 100, display: "flex", flexDirection: "row" }}
              >
                <div
                  className={`wash-wrapper border ${
                    selectedProgram.program_name == "Wash"
                      ? "border-primary"
                      : ""
                  }`}
                  style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    for (let z = 0; z < allwashPrograms.length; z++) {
                      if (allwashPrograms[z].program_name == "Wash") {
                        let pRate = allwashPrograms[z].program_rate;
                        setSelectedProgram(allwashPrograms[z]);
                        setDetergent("surfexcel");
                        setConditioner("softtouch");
                        setIronType(null);
                        for (let i = 0; i < variations.length; i++) {
                          if (
                            variations[i].v_name == "surfexcel" ||
                            variations[i].v_name == "softtouch"
                          ) {
                            pRate =
                              parseInt(pRate) + parseInt(variations[i].v_rate);
                          }
                        }
                        if (area != "none") {
                          setOrderStage(1);
                        }
                        if (area == "vatika") {
                          pRate = pRate + 10;
                        }
                        setProgramRate(pRate);
                      }
                    }
                  }}
                >
                  <div
                    className="p-wrapper-img-container simpleflex"
                    style={{ flex: 6 }}
                  >
                    <img
                      src={require("../assets/washprogram/washonly.png")}
                      style={{ height: 45, width: 45 }}
                    />
                  </div>
                  <div
                    className="p-wrapper-info-container simpleflex"
                    style={{ flex: 4 }}
                  >
                    <span>Wash</span>
                  </div>
                </div>
                <div
                  className={`wash-iron-wrapper border ${
                    selectedProgram.program_name == "Wash + Iron"
                      ? "border-primary"
                      : ""
                  }`}
                  style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    for (let z = 0; z < allwashPrograms.length; z++) {
                      if (allwashPrograms[z].program_name == "Wash + Iron") {
                        let pRate = allwashPrograms[z].program_rate;
                        setSelectedProgram(allwashPrograms[z]);
                        setDetergent("surfexcel");
                        setConditioner("softtouch");
                        setIronType("steam");
                        for (let i = 0; i < variations.length; i++) {
                          if (
                            variations[i].v_name == "surfexcel" ||
                            variations[i].v_name == "softtouch" ||
                            variations[i].v_name == "steam"
                          ) {
                            pRate =
                              parseInt(pRate) + parseInt(variations[i].v_rate);
                          }
                        }
                        if (area != "none") {
                          setOrderStage(1);
                        }
                        if (area == "vatika") {
                          pRate = pRate + 10;
                        }
                        setProgramRate(pRate);
                      }
                    }
                    addOrderRequest();
                  }}
                >
                  <div
                    className="p-wrapper-img-container simpleflex"
                    style={{ flex: 6 }}
                  >
                    <img
                      src={require("../assets/washprogram/washplusiron.png")}
                      style={{ height: 60, width: 60 }}
                    />
                  </div>
                  <div
                    className="p-wrapper-info-container simpleflex"
                    style={{ flex: 4 }}
                  >
                    <span>Wash + Iron</span>
                  </div>
                </div>
                <div
                  className={`iron-wrapper border ${
                    selectedProgram.program_name == "Iron"
                      ? "border-primary"
                      : ""
                  }`}
                  style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    for (let z = 0; z < allwashPrograms.length; z++) {
                      if (allwashPrograms[z].program_name == "Iron") {
                        let pRate = allwashPrograms[z].program_rate;
                        setSelectedProgram(allwashPrograms[z]);
                        setDetergent(null);
                        setConditioner(null);
                        setIronType("steam");
                        for (let i = 0; i < variations.length; i++) {
                          if (variations[i].v_name == "steam") {
                            pRate =
                              parseInt(pRate) + parseInt(variations[i].v_rate);
                          }
                        }
                        if (area != "none") {
                          setOrderStage(1);
                        }
                        if (area == "vatika") {
                          pRate = pRate + 5;
                        }
                        setProgramRate(pRate);
                      }
                    }
                  }}
                >
                  <div
                    className="p-wrapper-img-container simpleflex"
                    style={{ flex: 6 }}
                  >
                    <img
                      src={require("../assets/washprogram/irononly.png")}
                      style={{ height: 45, width: 45 }}
                    />
                  </div>
                  <div
                    className="p-wrapper-info-container simpleflex"
                    style={{ flex: 4 }}
                  >
                    <span>Iron</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="reviews-wrapper">
            <div className="reviews-header text-center">
              <span className="review-heading">User reviews</span>
            </div>
            <div className="reviews-inner-wrapper">
              <div className="reviews-cont">
                {hreviewloading && (
                  <div
                    className="simpleflex border"
                    style={{ minHeight: 336, flexDirection: "column" }}
                  >
                    <Spinner borderColor="#000000" size="28px" />
                  </div>
                )}
                {hreviewError && (
                  <div
                    className="wash-program border simpleflex"
                    style={{ minHeight: 336, flexDirection: "column" }}
                  >
                    <span className="my-1 text-center px-3">
                      Sorry, we couldn't fetch reviews from server, this is
                      development error, kindly report it on{" "}
                      <strong>complaint@shortwash.com</strong>
                    </span>
                  </div>
                )}
                {allReviews.map((item, index) => {
                  if (total_reviews < 3 && item.star_rating >= 4) {
                    total_reviews++;
                    return <UserReview data={item} key={index} />;
                  } else {
                    return "";
                  }
                })}
                {!hreviewloading && (
                  <div className="all-review-link-container text-center py-2 border">
                    <span
                      onClick={() => {
                        navigate("/reviews");
                      }}
                    >
                      View all Reviews
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="social-handles-wrapper"
            style={{ marginTop: 20, marginBottom: 30 }}
          >
            <div
              className="social-heading simpleflex py-3"
              style={{ color: "grey", fontStyle: "italic", fontSize: 17 }}
            >
              <span className="social-heading-span">Social Media -</span>
            </div>
            <SocialMedia />
          </div>
        </div>
      </div>
    </div>
  );
}
